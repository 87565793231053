import React from 'react';
import './OfflinePage.css';

const OfflinePage = () => {
    return (
        <div className="offline-container">
            <p className="loading-text">Parece que você está offline!</p>
        </div>
    );
};

export default OfflinePage;