import React from 'react';
import { withRouter } from "react-router-dom";
import './SignUp.css';

import UIButtonPrimary from '../../../Components/UI/ButtonPrimary/ButtonPrimary';
import api from '../../../services/api';
import moment from 'moment';

class PanelSignUp extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            nome: '',
            dia: '',
            mes: '',
            ano: '',
            sexo: '',
            estado_civil: '',
            instituicao: '',
            semestre: '',
            email: '',
            conf_email: '',
            senha: '',
            conf_senha: '',
            error: '',
            input_errors: {
                nome: '',
                dia: '',
                mes: '',
                ano: '',
                sexo: '',
                estado_civil: '',
                instituicao: '',
                semestre: '',
                email: '',
                conf_email: '',
                senha: '',
                conf_senha: ''    
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.blankFields = this.blankFields.bind(this);
        this.getYears = this.getYears.bind(this);
        this.getDays = this.getDays.bind(this);
        this.checkMatch = this.checkMatch.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.blankFields()){
            if(this.state.email !== this.state.conf_email){
                this.setState({
                    error: "Confirmação de email não corresponde"
                })    
            } else {
                if(this.state.senha !== this.state.conf_senha){
                    this.setState({
                        error: "Confirmação de senha não corresponde"
                    })    
                } else {
                    var dia = this.state.dia;
                    var mes = this.state.mes;
                    var ano = this.state.ano;
                    var data = ano+'-'+mes+'-'+dia;
                    var data_moment = moment(data);
                    var valida = data_moment.isValid();
                    var jsdata = data_moment.toDate();
                    if(valida){
                        const student = {
                            nome: this.state.nome,
                            data_nascimento: jsdata,
                            sexo: this.state.sexo,
                            estado_civil: this.state.estado_civil,
                            instituicao: this.state.instituicao,
                            semestre: this.state.semestre,
                            email: this.state.email,
                            senha: this.state.senha,    
                        }
                        api.post('/auth/estudante', student).then(res => {
                            if(res.status === 201){
                                localStorage.setItem("wid_token", res.data.token);
                                this.props.history.push('/teste/pre-teste/introducao');
                            } else {
                                this.setState({
                                    error: "Erro ao cadastrar, por favor tente novamente"
                                })     
                            }
                        })    
                    }else{
                        this.setState({
                            error: "Data de nascimento inválida"
                        })    
                    }
                }
            }
        } else {
            this.setState({
                error: "Preencha todos os campos do formulário"
            })
        }
    }

    blankFields(){
        if( !this.state.nome ||
            !this.state.dia ||
            !this.state.mes ||
            !this.state.ano ||
            !this.state.sexo ||
            !this.state.estado_civil ||
            !this.state.instituicao ||
            !this.state.semestre ||
            !this.state.email ||
            !this.state.conf_email ||
            !this.state.senha || 
            !this.state.conf_senha){
            this.setState({
                input_errors: {
                    nome: !this.state.nome,
                    dia: !this.state.dia,
                    mes: !this.state.mes,
                    ano: !this.state.ano,
                    sexo:  !this.state.sexo,
                    estado_civil:  !this.state.estado_civil,
                    instituicao:  !this.state.instituicao,
                    semestre:  !this.state.semestre,
                    email:  !this.state.email,
                    conf_email:  !this.state.conf_email,
                    senha:  !this.state.senha,
                    conf_senha:  !this.state.conf_senha 
                }
            })
            return true;
        }
        return false;
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState(() => ({
          [name]: value
        }), () => ( this.checkMatch() ));
    }

    checkMatch() {
        if(this.state.email && this.state.conf_email){
            if(this.state.email === this.state.conf_email){
                this.setState(() => ({
                    error: ''
                })) 
            }
        }
        if(this.state.senha && this.state.conf_senha){
            if(this.state.senha === this.state.conf_senha){
                this.setState(() => ({
                    error: ''
                }))    
            }     
        } 
        if(this.state.email && this.state.conf_email){
            if(this.state.email !== this.state.conf_email){
                this.setState(() => ({
                    error: "Confirmação de email não corresponde"
                }))    
            } 
        }
        if(this.state.senha && this.state.conf_senha){
            if(this.state.senha !== this.state.conf_senha){
                this.setState(() => ({
                    error: "Confirmação de senha não corresponde"
                }))   
            }  
        } 
    }

    getYears(inicio, final){
        let years = [];
        for (let index = inicio; index >= final; index--) {
            years.push(<option key={index} value={index}>{index}</option>)
        }
        return years;
    }

    getDays(inicio, final){
        let days = [];
        for (let index = inicio; index <= final; index++) {
            if(index < 10){
                days.push(<option key={index} value={'0'+index}>{'0'+index}</option>)
            } else {
                days.push(<option key={index} value={index}>{index}</option>)
            }
            
        }
        return days;
    }

    getSemesters(inicio, final){
        let semesters = [];
        for (let index = inicio; index <= final; index++) {
            semesters.push(<option value={index+'º'}>{index+'º'}</option>)            
        }
        return semesters;
    }

    render(){
        return(
            <div className="panel-singup">
                <div className="panel-singup-header">
                    <h1>CADASTRAR</h1>
                    <h4>Já possui cadastro? <a href="/">Faça Login</a></h4>
                </div>
                {this.state.error && <div className="error-warn">
                    <p>{this.state.error}</p>
                </div>}              
                <form onSubmit={this.handleSubmit} className="panel-singup-fields">
                    <div className={this.state.input_errors.nome ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-name"></span><h1>Nome Completo:</h1> 
                        </div>
                        <div className="input-2-field">
                            <input name="nome" type="text" value={this.state.nome} onChange={this.handleInputChange}/> 
                        </div>
                    </div>
                    <div className={this.state.input_errors.dia || this.state.input_errors.mes || this.state.input_errors.ano ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-dob"></span><h1>Data de Nascimento:</h1>
                        </div>
                        <div className="input-2-field">
                            <select name="ano" value={this.state.ano} onChange={this.handleInputChange}>
                                <option value=''>Ano</option>
                                {this.getYears(2010, 1960)}
                            </select>
                            <select name="mes" value={this.state.mes} onChange={this.handleInputChange}>
                                <option value=''>Mês</option>
                                <option value='01'>Janeiro</option>
                                <option value='02'>Fevereiro</option>
                                <option value='03'>Março</option>
                                <option value='04'>Abril</option>
                                <option value='05'>Maio</option>
                                <option value='06'>Junho</option>
                                <option value='07'>Julho</option>
                                <option value='08'>Agosto</option>
                                <option value='09'>Setembro</option>
                                <option value='10'>Outubro</option>
                                <option value='11'>Novembro</option>
                                <option value='12'>Dezembro</option>
                            </select>
                            <select name="dia" value={this.state.dia} onChange={this.handleInputChange}>
                                <option value=''>Dia</option>
                                {this.getDays(1, 31)}
                            </select>
                        </div>
                    </div>
                    <div className={this.state.input_errors.sexo ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-sex"></span><h1>Sexo:</h1>
                        </div>
                        <div className="input-2-field">
                            <select className="select-full-width"  name="sexo" value={this.state.sexo} onChange={this.handleInputChange}>
                                <option value=''>Selecionar</option>
                                <option value='Feminino'>Feminino</option>
                                <option value='Masculino'>Masculino</option>
                                <option value='Prefiro não dizer'>Prefiro não dizer</option>
                            </select>
                        </div>
                    </div>
                    <div className={this.state.input_errors.estado_civil ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-civil"></span><h1>Estado Civil:</h1>
                        </div>
                        <div className="input-2-field">
                            <select className="select-full-width" name="estado_civil" value={this.state.estado_civil} onChange={this.handleInputChange}>
                                <option value=''>Selecionar</option>
                                <option value='Solteiro(a)'>Solteiro(a)</option>
                                <option value='Casado(a) / União Estável'>Casado(a) / União Estável</option>
                                <option value='Viúva(a)'>Viúva(a)</option>
                            </select>
                        </div>
                    </div>
                    <div className={this.state.input_errors.instituicao ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-inst"></span><h1>Instituição de Ensino:</h1>
                        </div>
                        <div className="input-2-field">
                            <input name="instituicao" type="text" value={this.state.instituicao} onChange={this.handleInputChange}/>
                        </div>
                    </div>
                    <div className={this.state.input_errors.semestre ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-semester"></span><h1>Semestre que está cursando:</h1>
                        </div>
                        <div className="input-2-field">
                            <select className="select-full-width" name="semestre" value={this.state.semestre} onChange={this.handleInputChange}>
                                <option value=''>Selecionar</option>
                                {this.getSemesters(1, 10)}
                            </select>
                        </div>
                    </div>
                    <div className={this.state.input_errors.email || (this.state.email && this.state.conf_email && (this.state.email !== this.state.conf_email)) ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-email"></span><h1>Email:</h1>
                        </div>
                        <div className="input-2-field">
                            <input name="email" type="email" value={this.state.email} onChange={this.handleInputChange}/> 
                        </div>
                    </div>
                    <div className={this.state.input_errors.conf_email || (this.state.email && this.state.conf_email && (this.state.email !== this.state.conf_email)) ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-email"></span><h1>Confirmar Email:</h1>
                        </div>
                        <div className="input-2-field">
                            <input name="conf_email" type="email" value={this.state.conf_email} onChange={this.handleInputChange}/> 
                        </div>
                    </div>
                    <div className={this.state.input_errors.senha || this.state.senha && this.state.conf_senha && (this.state.senha !== this.state.conf_senha) ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-pass"></span><h1>Senha:</h1>
                        </div>
                        <div className="input-2-field">
                            <input name="senha" type="password" value={this.state.senha} onChange={this.handleInputChange}/> 
                        </div>
                    </div>
                    <div className={this.state.input_errors.conf_senha || this.state.senha && this.state.conf_senha && (this.state.senha !== this.state.conf_senha) ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-signup icon-signup-pass"></span><h1>Confirmar Senha:</h1>
                        </div>
                        <div className="input-2-field">
                            <input name="conf_senha" type="password" value={this.state.conf_senha} onChange={this.handleInputChange}/> 
                        </div>
                    </div>
                    <div className="panel-login-button">
                        <UIButtonPrimary text="CONTINUAR"/>
                    </div>
                </form>                
            </div>
        )
    }
}

export default withRouter(PanelSignUp);